













































import Component, { mixins } from "vue-class-component";
import { EmailFormMixin } from "@/mixins/email-form.mixin";

@Component
export default class SimpleContactForm extends mixins(EmailFormMixin) {}
